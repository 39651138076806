import React, { Suspense, lazy } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { ProtectedRoute, AuthenticateRoute } from "./ProtectedRoute"
import { AdminAuthenticateRoute, AdminRoute } from "./pages/admin/AdminRoute"
import GifloaderMini from "./layout/gifloadermini"

// Lazy load components
const Accounts = lazy(() => import("./pages/accounts"))
const BuySell = lazy(() => import("./pages/buy-sell"))
const PaymentMethod = lazy(() => import("./pages/payment-method"))
const Dashboard = lazy(() => import("./pages/index"))
const Landing = lazy(() => import("./pages/static/landing"))
const TermsAndConditions = lazy(() => import("./pages/static/terms-and-conditions"))
const PrivacyPolicy = lazy(() => import("./pages/static/privacy-policy"))
const Reset = lazy(() => import("./pages/reset"))
const Settings = lazy(() => import("./pages/settings"))
const Preferences = lazy(() => import("./pages/settings-preferences"))
const Signin = lazy(() => import("./pages/signin"))
const Signup = lazy(() => import("./pages/signup"))
const Deposits = lazy(() => import("./pages/deposits"))
const Withdrawals = lazy(() => import("./pages/withdrawals"))
const Transactions = lazy(() => import("./pages/transactions"))
const PageNotFound = lazy(() => import("./pages/page-not-found"))
const About = lazy(() => import("./pages/static/about"))
const ReferralProgram = lazy(() => import("./pages/referral-program"))
const Wallet = lazy(() => import("./pages/static/wallet"))
const Gettingstarted = lazy(() => import("./pages/getting-started"))
const Learn = lazy(() => import("./pages/static/learn"))
const Consultation = lazy(() => import("./pages/static/consultation"))
const ExternalWallet = lazy(() => import("./pages/external-wallet"))
const Logout = lazy(() => import("./shared/Logout"))
const InstantBuySellandWithdraw = lazy(() => import("./pages/Instant-Buy-Sell-and-Withdraw"))
const RealTimeQuotes = lazy(() => import("./pages/Real-Time-Quotes"))
const NonCustodial = lazy(() => import("./pages/Non-Custodial"))
const SafeandSecureCryptoWallet = lazy(() => import("./pages/Safe-and-Secure-Crypto-Wallet"))
const AdminLogin = lazy(() => import("./pages/admin/AdminLogin"))
const CustomerList = lazy(() => import("./pages/admin/CustomerList"))
const CustomerDetails = lazy(() => import("./pages/admin/CustomerDetails"))
const Trades = lazy(() => import("./pages/admin/Trades"))
const CustomerAccounts = lazy(() => import("./pages/admin/CustomerAccounts"))
const Sales = lazy(() => import("./pages/admin/Sales"))
const Faq = lazy(() => import("./pages/static/faq"))
const Windsor = lazy(() => import("./pages/static/serviceArea/Windsor"))
const UserAgreement = lazy(() => import("./pages/static/user-agreement"))
const ResetPassword = lazy(() => import("./pages/reset-password"))
const Otc = lazy(() => import("./pages/static/otc"))
const Contact = lazy(() => import("./pages/static/contact"))
const WIIBitcoin = lazy(() => import("./pages/static/whatItIs/Bitcoin"))
const WIILitecoin = lazy(() => import("./pages/static/whatItIs/Litecoin"))
const WIIBitcoinCash = lazy(() => import("./pages/static/whatItIs/BitcoinCash"))
const WIIXRP = lazy(() => import("./pages/static/whatItIs/XRP"))
const WIIEther = lazy(() => import("./pages/static/whatItIs/Ether"))
const WIIStellarLumensXLM = lazy(()=> import("./pages/static/whatItIs/StellarLumensXLM"))
const WIIDogeCoin = lazy(()=> import("./pages/static/whatItIs/DogeCoin"))
const WIIcardano = lazy(()=> import("./pages/static/whatItIs/Cardano"))
const WIIChainlink = lazy(()=> import("./pages/static/whatItIs//Chainlink"))
const WIIPolkadot = lazy(()=> import("./pages/static/whatItIs/Polkadot"))
const WIIBlockchain = lazy(()=> import("./pages/static/whatItIs/Blockchain"))
const WIIDecentralizedFinance = lazy(()=> import("./pages/static/whatItIs/DecentralizedFinance"))
const HTBBitcoin = lazy(() => import("./pages/static/howToBuy/Bitcoin"))
const HTBLitecoin = lazy(() => import("./pages/static/howToBuy/Litecoin"))
const HTBEther = lazy(() => import("./pages/static/howToBuy/Ether"))
const HTBBitcoinCash = lazy(() => import("./pages/static/howToBuy/BitcoinCash"))
const HTBXRP = lazy(() => import("./pages/static/howToBuy/XRP"))
const HTBStellarLumens = lazy(()=> import("./pages/static/howToBuy/StellarLumensXLM"))
const HTBDogeCoin = lazy(()=> import("./pages/static/howToBuy/DogeCoin"))
const HTBCardano = lazy(()=> import("./pages/static/howToBuy/Cardano"))
const HTBChainlink = lazy(()=> import("./pages/static/howToBuy/Chainlink"))
const HTBPolkadot = lazy(()=> import("./pages/static/howToBuy/Polkadot"))

function Index() {
  return (
    <BrowserRouter>
      <div id="main-wrapper">
        <Suspense fallback={<div className="vh-100 d-flex align-items-center justify-content-center"> <GifloaderMini /> </div>}>
          <Routes>
            <Route
              path="/admin/signin"
              element={
                <AdminAuthenticateRoute>
                  <AdminLogin />
                </AdminAuthenticateRoute>
              }
            />
            <Route
              path="/admin/customer"
              element={
                <AdminRoute>
                  <CustomerList />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/customer/:guid"
              element={
                <AdminRoute>
                  <CustomerDetails />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/trades"
              element={
                <AdminRoute>
                  <Trades />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/customer-accounts"
              element={
                <AdminRoute>
                  <CustomerAccounts />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/sales"
              element={
                <AdminRoute>
                  <Sales />
                </AdminRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/exchange"
              element={
                <ProtectedRoute>
                  <BuySell />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assets"
              element={
                <ProtectedRoute>
                  <Accounts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            
            <Route path="/" element={<Landing />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/user-agreement" element={<UserAgreement />} />
            <Route path="/getting-started" element={<Gettingstarted />} />

            <Route
              path="/withdrawals"
              element={
                <ProtectedRoute>
                  <Withdrawals />
                </ProtectedRoute>
              }
            />
            <Route
              path="/orders"
              element={
                <ProtectedRoute>
                  <Transactions />
                </ProtectedRoute>
              }
            />
            <Route
              path="/deposits"
              element={
                <ProtectedRoute>
                  <Deposits />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payment-method"
              element={
                <ProtectedRoute>
                  <PaymentMethod />
                </ProtectedRoute>
              }
            />

            <Route
              path="/external-wallet"
              element={
                <ProtectedRoute>
                  <ExternalWallet />
                </ProtectedRoute>
              }
            />

            <Route
              path="/settings-preferences"
              element={
                <ProtectedRoute>
                  <Preferences />
                </ProtectedRoute>
              }
            />

            <Route
              path="/signin"
              element={
                <AuthenticateRoute>
                  <Signin />
                </AuthenticateRoute>
              }
            />
            
            <Route path="/signup" element={
                <AuthenticateRoute>
                  <Signup />
                </AuthenticateRoute>
              } />
            <Route path="/reset" element={<Reset />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            
            <Route path="/about" element={<About/>} />
            <Route path="/referral-program" element={<ReferralProgram/>} />
            <Route path="/wallet" element={<Wallet/>} />
            <Route path="/learn" element={<Learn/>} />
            <Route path="/faq" element={<Faq/>} />
            <Route path="/services" element={<Navigate to="/crypto-consultations"/>} />
            <Route path="/crypto-consultations" element={<Consultation/>} />
            <Route path="/otc" element={<Otc/>} />
            <Route path="/buy-crypto-in-windsor" element={<Windsor/>} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/Instant-Buy-Sell-and-Withdraw" element={<InstantBuySellandWithdraw />} />
            <Route path="/Real-Time-Quotes" element={<RealTimeQuotes />} />
            <Route path="/Non-Custodial" element={<NonCustodial />} />
            <Route path="/Safe-and-Secure-Crypto-Wallet" element={<SafeandSecureCryptoWallet />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/what-is-bitcoin-btc" element={<WIIBitcoin />} />
            <Route path="/what-is-ether" element={<WIIEther />} />
            <Route path="/what-is-litecoin" element={<WIILitecoin />} />
            <Route path="/what-is-bitcoin-cash" element={<WIIBitcoinCash />} />
            <Route path="/what-is-XRP" element={<WIIXRP />} />
            <Route path="/what-is-stellar" element={<WIIStellarLumensXLM />} />
            <Route path="/what-is-dogecoin" element={<WIIDogeCoin />} />
            <Route path="/what-is-cardano" element={<WIIcardano />} />
            <Route path="/what-is-chainlink" element={<WIIChainlink />} />
            <Route path="/what-is-polkadot" element={<WIIPolkadot />} />
            <Route path="/What-is-a-blockchain" element={<WIIBlockchain />} />
            <Route path="/decentralized-finance-defi-wallet" element={<WIIDecentralizedFinance />} />

            <Route path="/how-to-buy-bitcoin-canada" element={<HTBBitcoin />} />
            <Route path="/how-to-buy-ether" element={<HTBEther />} />
            <Route path="/how-to-buy-litecoin" element={<HTBLitecoin />} />
            <Route path="/how-to-buy-bitcoin-cash" element={<HTBBitcoinCash />} />
            <Route path="/how-to-buy-XRP" element={<HTBXRP />} />
            <Route path="/how-to-buy-stellar" element={<HTBStellarLumens />} />
            <Route path="/how-to-buy-dogecoin" element={<HTBDogeCoin />} />
            <Route path="/how-to-buy-cardano" element={<HTBCardano />} />
            <Route path="/how-to-buy-chainlink" element={<HTBChainlink />} />
            <Route path="/how-to-buy-polkadot" element={<HTBPolkadot />} />
          </Routes>
        </Suspense>
      </div>
    </BrowserRouter>
  )
}

export default Index
